import { useMaterialReactTable } from 'material-react-table';
import { formatDateString } from 'src/utils/date';
import { SimpleFormatNumber, beautifyEnum } from 'src/utils/utility';
import EyeIcon from 'src/assets/images/icons/eye.svg';
import { CO2, Kg, M3, Ton } from 'src/presentations/components/atom/unit';
import StatusChip from 'src/presentations/components/organisms/dashboard/StatusChip';

export const useAnalyticsTable = (tableOptions) =>
  useMaterialReactTable({
    enableSorting: false,
    enablePinning: false,
    enableRowDragging: false,
    enableRowSelection: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableSortingRemoval: false,
    enableEditing: true,
    editDisplayMode: 'table',
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    ...tableOptions,
  });

export const getShipmentsColumns = ({ defaultCurrency }: { defaultCurrency?: string }) => [
  {
    enableEditing: false,
    accessorKey: 'shipmentNumber',
    accessorFn: (row) => row.shipmentNumber || '-',
    header: 'SHIP NO.',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'departureDate',
    accessorFn: (row) => <span className='w-100'>{formatDateString(row.departureDate) || '-'}</span>,
    header: 'PICKUP DATE',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'arrivalDate',
    accessorFn: (row) => <span className='w-100'>{formatDateString(row.arrivalDate) || '-'}</span>,
    header: 'ARRIVAL DATE',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceCount',
    accessorFn: (row) => row.invoiceCount || '-',
    header: 'NO. INVOICES',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceChargesCount',
    accessorFn: (row) => row.invoiceChargesCount || '-',
    header: 'NO. CHARGES ',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceAmountAfterTax',
    accessorFn: (row) => `${row?.invoiceCurrency || '-'} ${SimpleFormatNumber(row?.invoiceAmountAfterTax, row?.invoiceCurrency === 'JPY') || '-'}`,
    header: (
      <div>
        <div>TOTAL AMOUNT</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Invoice Currency</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: false,
    accessorKey: 'totalAmount',
    accessorFn: (row) => {
      const currency = row?.totalAmountCurrency || defaultCurrency;
      return `${currency || '-'} ${SimpleFormatNumber(row?.totalAmount, currency === 'JPY') || '-'}`;
    },
    header: (
      <div>
        <div>TOTAL AMOUNT</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Currency Reference</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'grossWeight',
    accessorFn: (row) => (
      <span>
        {SimpleFormatNumber(row.grossWeight) || '-'} <Kg />
      </span>
    ),
    header: 'GROSS WEIGHT',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeableWeight',
    accessorFn: (row) => (
      <span>
        {SimpleFormatNumber(row.chargeableWeight) || '-'} <Kg />
      </span>
    ),
    header: 'CHARGEABLE WEIGHT ',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'volume',
    accessorFn: (row) => (
      <span>
        {row.volume || '-'} <M3 />
      </span>
    ),
    header: 'VOLUME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'co2Emission',
    accessorFn: (row) => (
      <span>
        {row.co2Emission} <Ton />
      </span>
    ),
    header: (
      <>
        <CO2 /> EMISSION
      </>
    ),
    size: 150,
  },
  {
    enableEditing: false,
    accessorKey: 'serviceProvider',
    accessorFn: (row) => beautifyEnum(row.serviceProvider) || '-',
    header: 'SERVICE PROVIDER',
    size: 150,
  },
  {
    enableEditing: false,
    accessorKey: 'modality',
    accessorFn: (row) => row.modality || '-',
    header: 'MODALITY',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'vehicleId',
    accessorFn: (row) => row.vehicleId || '-',
    header: 'VEHICLE ID',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'shipper',
    accessorFn: (row) => beautifyEnum(row.shipper)?.toUpperCase() || '-',
    header: 'SHIPPER NAME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'pol',
    accessorFn: (row) => row.pol || '-',
    header: 'POL',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'shipperCountry',
    accessorFn: (row) => row.shipperCountry || '-',
    header: 'SHIPPER COUNTRY',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'consignee',
    accessorFn: (row) => row.consignee || '-',
    header: 'CONSIGNEE NAME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'pod',
    accessorFn: (row) => row.pod || '-',
    header: 'POD ',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'consigneeCountry',
    accessorFn: (row) => row.consigneeCountry || '-',
    header: 'CONSIGNEE COUNTRY',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'goodsName',
    accessorFn: (row) => row.goodsName || '-',
    header: 'GOODS NAME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'packageCount',
    accessorFn: (row) => row.packageCount || '-',
    header: 'NO. PACKAGES',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'packageUnit',
    accessorFn: (row) => row.packageUnit || '-',
    header: 'PACKAGE UNIT',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => (
      <button type='button' onClick={() => window.open(`/action-details/${row.invoiceId}?hideBack=1`, '_blank')}>
        <img src={EyeIcon} alt='Detail' />
      </button>
    ),
    header: 'DETAILS',
    accessorKey: 'actions',
    enableSorting: false,
    size: 20,
  },
];

export const getInvoicesColumns = ({ defaultCurrency }: { defaultCurrency?: string }) => [
  {
    enableEditing: false,
    accessorKey: 'invoiceNumber',
    accessorFn: (row) => row.invoiceNumber || '-',
    header: 'INVOICE NO.',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceStatus',
    accessorFn: (row) => <StatusChip status={row.invoiceStatus} />,
    header: 'STATUS ',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'shipmentNumber',
    accessorFn: (row) => row.shipmentNumber || '-',
    header: 'SHIP NO.',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceDate',
    accessorFn: (row) => <span className='w-100'>{formatDateString(row.invoiceDate) || '-'}</span>,
    header: 'INVOICE DATE',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceDueDate',
    accessorFn: (row) => <span className='w-100'>{formatDateString(row.invoiceDueDate) || '-'}</span>,
    header: 'INVOICE DUE DATE',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceChargesCount',
    accessorFn: (row) => row.invoiceChargesCount || '-',
    header: 'NO. CHARGES',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'totalAmount',
    accessorFn: (row) => `${row?.invoiceCurrency || '-'} ${SimpleFormatNumber(row?.totalAmount, row?.invoiceCurrency === 'JPY') || '-'}`,
    header: (
      <div>
        <div>TOTAL AMOUNT</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Invoice Currency</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: false,
    accessorKey: 'totalAmountInReferenceCurrency',
    accessorFn: (row) => {
      const currency = row?.invoiceReferenceCurrency || defaultCurrency;
      return `${currency || '-'} ${SimpleFormatNumber(row?.totalAmountInReferenceCurrency, currency === 'JPY') || '-'}`;
    },
    header: (
      <div>
        <div>TOTAL AMOUNT</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Currency Reference</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'serviceProvider',
    accessorFn: (row) => beautifyEnum(row.serviceProvider) || '-',
    header: 'SERVICE PROVIDER',
    size: 150,
  },
  {
    enableEditing: false,
    accessorKey: 'modality',
    accessorFn: (row) => row.modality || '-',
    header: 'MODALITY',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'billedEntity',
    accessorFn: (row) => row.billedEntity || '-',
    header: 'BILLED ENTITY NAME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'shipper',
    accessorFn: (row) => beautifyEnum(row.shipper)?.toUpperCase() || '-',
    header: 'SHIPPER NAME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'pol',
    accessorFn: (row) => row.pol || '-',
    header: 'POL',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'shipperCountry',
    accessorFn: (row) => row.shipperCountry || '-',
    header: 'SHIPPER COUNTRY',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'consignee',
    accessorFn: (row) => row.consignee || '-',
    header: 'CONSIGNEE NAME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'pod',
    accessorFn: (row) => row.pod || '-',
    header: 'POD ',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'consigneeCountry',
    accessorFn: (row) => row.consigneeCountry || '-',
    header: 'CONSIGNEE COUNTRY',
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: false,
    accessorKey: 'goodsPoRef',
    accessorFn: (row) => row?.goodsPoRef || '-',
    header: 'GOODS PO REF',
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: false,
    accessorKey: 'fapRef',
    accessorFn: (row) => row?.fapRef || '-',
    header: 'FAP REF',
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: false,
    accessorKey: 'submissionDate',
    accessorFn: (row) => row?.submissionDate || '-',
    header: 'SUBMISSION DATE',
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: false,
    accessorKey: 'resolutionDate',
    accessorFn: (row) => row?.resolutionDate || '-',
    header: 'RESOLUTION DATE',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => (
      <button type='button' onClick={() => window.open(`/action-details/${row.invoiceId}?hideBack=1`)}>
        <img src={EyeIcon} alt='Detail' />
      </button>
    ),
    header: 'DETAILS',
    enableSorting: false,
    accessorKey: 'actions',
    size: 20,
  },
];

export const getChargesColumns = ({ defaultCurrency }: { defaultCurrency?: string }) => [
  {
    enableEditing: false,
    accessorKey: 'shipmentNumber',
    accessorFn: (row) => row.shipmentNumber || '-',
    header: 'SHIP NO.',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceNumber',
    accessorFn: (row) => row.invoiceNumber || '-',
    header: 'INVOICES NO.',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoiceDate',
    accessorFn: (row) => <span className='w-100'>{formatDateString(row.invoiceDate) || '-'}</span>,
    header: 'INVOICE DATE',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeDomain',
    accessorFn: (row) => beautifyEnum(row.chargeDomain)?.toUpperCase() || '-',
    header: 'CHARGE DOMAIN',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeName',
    accessorFn: (row) => row.chargeName || '-',
    header: 'CHARGE NAME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeAmount',
    accessorFn: (row) => `${row?.chargeCurrency || '-'} ${SimpleFormatNumber(row?.chargeAmount, row?.chargeCurrency === 'JPY') || '-'}`,
    header: (
      <div>
        <div>CHARGE AMOUNT</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Original Currency</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeAmountInInvoiceCurrency',
    accessorFn: (row) => `${row?.invoiceCurrency || '-'} ${SimpleFormatNumber(row?.chargeAmountInInvoiceCurrency, row?.invoiceCurrency === 'JPY') || '-'}`,
    header: (
      <div>
        <div>CHARGE AMOUNT</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Invoice Currency</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeAmountInReferenceCurrency',
    accessorFn: (row) => {
      const currency = row?.referenceCurrency || defaultCurrency;
      return `${currency || '-'} ${SimpleFormatNumber(row?.chargeAmountInReferenceCurrency, currency === 'JPY') || '-'}`;
    },
    header: (
      <div>
        <div>CHARGE AMOUNT</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Currency Reference</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeQuantity',
    accessorFn: (row) => row.chargeQuantity || '-',
    header: 'CHARGE QUANTITY',
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: false,
    accessorKey: 'quantityUnit',
    accessorFn: (row) => row.quantityUnit || '-',
    header: 'QUANTITY UNIT',
    size: 150,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeUnitPrice',
    accessorFn: (row) => `${row?.chargeCurrency || '-'} ${SimpleFormatNumber(row?.chargeUnitPrice, row?.chargeCurrency === 'JPY') || '-'}`,
    header: (
      <div>
        <div>UNIT PRICE</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Original Currency</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'exchangeRate',
    accessorFn: (row) =>
      `${row?.chargeCurrency || '-'} 1 = ${row?.invoiceCurrency || '-'} ${SimpleFormatNumber(row?.exchangeRate, row?.invoiceCurrency === 'JPY') || '-'}`,
    header: (
      <div>
        <div>EXCHANGE RATE</div>
        <span className='text-xs font-medium leading-4 text-[#808080]'>Original Ccy to Invoice Ccy</span>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'serviceProvider',
    accessorFn: (row) => beautifyEnum(row.serviceProvider) || '-',
    header: 'SERVICE PROVIDER',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'modality',
    accessorFn: (row) => row.modality || '-',
    header: 'MODALITY',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'pol',
    accessorFn: (row) => row.pol || '-',
    header: 'POL',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'pod',
    accessorFn: (row) => row.pod || '-',
    header: 'POD ',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => (
      <button type='button' onClick={() => window.open(`/action-details/${row.invoiceId}?hideBack=1`)}>
        <img src={EyeIcon} alt='Detail' />
      </button>
    ),
    header: 'DETAILS',
    enableSorting: false,
    accessorKey: 'actions',
    size: 20,
  },
];

export enum TableTab {
  SHIPMENTS = 'Shipments',
  INVOICES = 'Invoices',
  CHARGES = 'Charges',
  SAVINGS = 'SAVINGS',
  VOLUMETRIC = 'VOLUMETRIC',
}
